import React from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
} from '@mui/material';
import logo from '../logo.png';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  otpContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '240px', // Adjust the width as per your preference
  },
  otpInput: {
    width: '36px', // Adjust the width and height to make the boxes square
    height: '36px',
    textAlign: 'center',
    fontSize: '18px',
  },
});

// eslint-disable-next-line react/prop-types
function Home({ res, id, loading,handleStaffIdChange,handleLoginWithStaffId,staffId,handleVerifyOTP,otp,status,verificationSid, handleOtpChange}) {
  const classes = useStyles();
 

  

  return (
    <div className="homepage">
      <img src={logo} alt="" className="homeImage" />
      <GoogleOAuthProvider clientId={id}>
        <GoogleLogin
          render={(renderProps) => (
            <Button
              variant="contained"
              size="large"
              className="homebutton"
              onClick={renderProps.onClick}
              endIcon={<GoogleIcon />}
            >
              {loading ? <CircularProgress color="inherit" /> : 'LOGIN'}
            </Button>
          )}
          clientId={id}
          onSuccess={res}
          onFailure={res}
          cookiePolicy="single_host_origin"
        />
      </GoogleOAuthProvider>

      <h2>OR Login with your Easysolar ID</h2>
      <br />
      {verificationSid ? (
        <div>
        <FormControl component="fieldset">
          <div className={classes.otpContainer}>
          {[0, 1, 2, 3, 4, 5].map((index) => (
          <TextField
            key={index}
            id={`otpInput-${index}`}
            className={classes.otpInput}
            type="text"
            inputProps={{ maxLength: 1 }}
            value={otp[index] || ''}
            onChange={(e) => handleOtpChange(index, e.target.value)}
            autoFocus={index === 0} // Automatically focus on the first input box
            onKeyDown={(e) => {
              if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
                const previousInput = document.getElementById(`otpInput-${index - 1}`);
                if (previousInput) {
                  previousInput.focus();
                }
              }
            }}
          />
        ))}
            
          </div>
        </FormControl>
        <div>
          <br />
          <br />
          <Button
            variant="contained"
            size="large"
            className="homebutton"
            onClick={handleVerifyOTP}
          >
            {loading ? <CircularProgress color="inherit" /> : 'Verify OTP'}
          </Button>
        </div>
      </div>
      ) : (
        <div>
          <FormControl component="fieldset">
            <TextField
              id="staffid"
              label="Enter StaffID"
              variant="outlined"
              value={staffId}
              onChange={handleStaffIdChange}
              placeholder="Enter StaffID"
              InputProps={{
                startAdornment: <InputAdornment position="start">ES</InputAdornment>,
              }}

            />
          </FormControl>
          
            <div>
            <br />
            <Button
              variant="contained"
              size="large"
              className="homebutton"
              onClick={handleLoginWithStaffId}
            >
              {loading ? <CircularProgress color="inherit" /> : 'LOGIN'}
            </Button>
          </div>
        </div>
      )}
    {status && <p>{status}</p>}
    </div>
  );
}

export default Home;
